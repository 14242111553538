import './Result.css';

export default function Result(props) {

  return(
    <section id="result-container" className={props.winner === undefined?"hidden":""}>
      <div>
        <h1>{props.winner==="player"?"You won, well isn't that lovely?":"In the words of 21 vapes, you lost, you little scumbag"}</h1>
        <p>Jack, if you promise to play this game with Max Fosh, I'll make it multiplayer in a week :)</p>
        <div>
          <button className="red" onClick={() => {window.open("https://www.youtube.com/channel/UCpCJRHoggwXQhuFbW4gjM_w?sub_confirmation=1","_blank")}}>Subscribe to PopUpPirate</button>
          <button onClick={() => {window.location.reload()}}>Play Again</button>
        </div>
      </div>
    </section>
  )
}
