import Result from "./Result";
import Three from "./Three";
import { useState } from 'react';

export default function App() {
  const [winner, setWinner] = useState(undefined);
  return (
    <div className="App">
      <Three onEndGame={setWinner}/>
      <Result winner={winner}/>
    </div>
  );
}