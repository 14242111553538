import { Component } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Barrel from './models/barrel.glb';
import Pirate from './models/pirate.glb';
import Slot from './models/slot.glb';
import Sword from './models/sword.glb';
import './Three.css';
import OrbitControls from "three-orbitcontrols";

const loader = new GLTFLoader();

export default class Three extends Component{

  constructor(props){
    super(props)
    this.onEndGame = props.onEndGame;
  }

  newGame = () => {
    this.setup = Array(24).fill(null)
    this.swordPositions = Array(24).fill(2)
    this.yOffset = -0.5
    this.playerColour = "900707"
    this.turn = "player"
    this.endingSlot = Math.floor(Math.random() * 24)
  }

  render = () => {
    return(
      <>
        <p id="info">Drag to rotate</p>
        <div
          id="renderer-container"
          style={{width: "100vw", height: "100vh"}}
          ref={mount => { this.mount = mount }}>
        </div>
      </>
    )
  }

  componentDidMount = () => {

    this.newGame();

    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    this.scene = new THREE.Scene();

    // RENDERER
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setClearColor("#E5E5E5");
    this.renderer.setSize( width, height);
    this.renderer.setPixelRatio( devicePixelRatio );
    this.mount.appendChild(this.renderer.domElement);
    // CAMERA
    this.camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 1000);
    this.camera.position.z = 5;
    this.camera.position.y = 2.5;
    // ORBIT CONTROLS
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.autoRotate = true;
    this.controls.autoRotateSpeed = -1.5;
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.1
    this.controls.enablePan = false;
    this.controls.enableZoom = false;
    this.controls.keyPanSpeed = 5;
    this.controls.minPolarAngle = 1
    this.controls.maxPolarAngle = 1.8
    // LIGHTS
    var lights = [];
    lights[0] = new THREE.PointLight(0x304ffe, 4, 0);
    lights[1] = new THREE.PointLight(0xffffff, 1.5, 0);
    lights[2] = new THREE.PointLight(0xffffff, 1.5, 0);
    lights[3] = new THREE.PointLight(0xffffff, 1.5, 0);
    lights[4] = new THREE.PointLight(0xffffff, 1.5, 0);
    lights[0].position.set(0, 200, 0);
    lights[1].position.set(100, 50, 100);
    lights[2].position.set(-100, 50, -100);
    lights[3].position.set(-100, 50, 100);
    lights[4].position.set(100, 50, -100);
    this.scene.add(lights[0]);
    this.scene.add(lights[1]);
    this.scene.add(lights[2]);
    this.scene.add(lights[3]);
    this.scene.add(lights[4]);
    // RAYCASTER
    this.raycaster = new THREE.Raycaster();
    this.raycaster.far = 6
    // MOUSE POSITION
    this.mouse = new THREE.Vector2(-2,-2);

    // MODELS
    loader.load(Barrel, (model) => {
      this.barrel = model.scene;
      this.scene.add(model.scene);
    })
    loader.load(Pirate, (model) => {
      this.pirate = model.scene;
      this.pirate.position.y = this.yOffset
      this.scene.add(model.scene);
    })
    loader.load(Slot, (model) => {
      this.slots = []
      console.log(model.scene)
      for(var i = 0; i < 24; i ++){
        this.slots[i] = model.scene.clone();
        this.slots[i].scale.x = 1.02;
        this.slots[i].rotation.y = (i * 2 * Math.PI / 24);
        this.slots[i].rotateZ((i % 2) * 0.2 - 0.1)
        this.scene.add(this.slots[i]);
      }
    })
    loader.load(Sword, (model) => {
      this.swords = []
      for(var i = 0; i < 24; i ++){
        this.swords[i] = model.scene.clone();
        this.swords[i].children[0].material = model.scene.children[0].material.clone();
        this.swords[i].rotation.y = (i * 2 * Math.PI / 24);
        this.swords[i].rotateZ((i % 2) * 0.2 - 0.1)
        this.swords[i].translateY((i % 2) * 0. - 0.2);
        this.swords[i].translateX(2)
        this.swords[i].children[0].material.opacity = 0
        this.swords[i].children[0].material.transparent = true
        this.scene.add(this.swords[i]);
      }
    })



    this.renderScene();
    //start animation
    this.renderFrame();
    this.resizeCanvas()

    this.canvas = document.querySelector("div#renderer-container > canvas")
    this.canvas.classList.add('mouseup')

    window.addEventListener('resize', this.resizeCanvas)
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mousedown', () => {
      this.updateCursor("mousedown")
      this.mouseDown = true
    })
    window.addEventListener('mouseup', () => {
      this.updateCursor("mouseup")
      this.mouseDown = false
    })
    window.addEventListener('click', () => {
      this.handleClick()
    })
    window.addEventListener('touchstart', (event) => {
      this.onMouseMove(event.changedTouches[0])
    })
    window.addEventListener('touchend', () => {
      this.handleClick()
    })
  }
  
  handleClick = () => {
    if(this.setup[this.hoverSlot] === null && this.turn === "player") {
      this.setup[this.hoverSlot] = 'player'
      this.mostRecentSlot = this.hoverSlot
      this.turn = "opponent"
      if(this.hoverSlot === this.endingSlot){
        this.endGame(false)
      } else {
        this.takeTurn();
      }
    }
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId);
  }

  endGame = (playerWins) => {
    setTimeout(() => {
      this.isEndGame = true;
      this.onEndGame(playerWins?"player":"opponent")
    }, 500)
  } 

  takeTurn = () => {
    setTimeout(() => {
      // Try to pick a slot near where the player just went, and only check all 24 if non seem to work
      var nearSlotAttempts = 0
      var pos;
      while(true){
        if(nearSlotAttempts < 10){
          pos = this.mostRecentSlot + Math.floor((Math.random() - 0.5) * 10)
        } else {
          pos = Math.floor(Math.random() * 24)
        }
        if(this.setup[pos] === null){
          this.setup[pos] = "opponent"
          if(pos === this.endingSlot){
            this.endGame(true)
          }
          setTimeout(() => {
            this.turn = "player"
          },500)
          break;
        }
        nearSlotAttempts += 1
      }
    },800)
  }


  renderFrame = () => {
    this.frameId = window.requestAnimationFrame(this.renderFrame);

    if(this.isEndGame && this.yOffset > -2){
      this.yOffset -= 0.2
      this.pirate.translateY(0.02)
    }

    if(this.barrel){
      this.barrel.position.y = this.yOffset
    }

    if(this.slots && this.mouse) {

      this.raycaster.setFromCamera(this.mouse,this.camera);
      const intersects = this.raycaster.intersectObjects(this.slots, true);
      const intersectsObjects = intersects.map(i => i.object);


      for(var i = 0; i < this.slots.length; i++){
        
        this.slots[i].scale.x = 1.02;
        this.slots[i].scale.y = 1;
        this.slots[i].scale.z = 1;

        // As the first object is the clsoest, only update that one
        if(intersectsObjects.length > 0){
            if(intersectsObjects[0].parent.parent === this.slots[i]){
              this.hoverSlot = i
              intersectsObjects[0].parent.parent.scale.x = 1.05
              intersectsObjects[0].parent.parent.scale.y = 1.05
              intersectsObjects[0].parent.parent.scale.z = 1.05
            }
        } else {
          this.hoverSlot = null
        }
        this.slots[i].position.y = this.yOffset + ((i % 2) * 0.4 - 0.2)
      }
    }

    if(this.swords) {
    
      for(var i = 0; i < this.swords.length; i++){

        var color = "3d4147"
        if(this.setup[i] !== null){
          if(this.swordPositions[i] > 0.1){
            this.swords[i].translateX(-0.1)
            this.swordPositions[i] -= 0.1
            this.swords[i].children[0].material.opacity += 0.1
          }
          if(this.setup[i] === 'player'){
            color = this.playerColour;
          }
        }
        this.swords[i].children[0].material.color.setHex(`0x00${color}`)


        this.swords[i].position.y = this.yOffset + ((i % 2) * 0.4 - 0.2)
      }
    
    }




    this.controls.update();
    this.renderScene();
  };

  renderScene = () => {
    if (this.renderer) this.renderer.render(this.scene, this.camera);
  }

  resizeCanvas = () => {
    this.renderer.setSize( window.innerWidth, window.innerHeight);
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
  }
  
  updateCursor = (mouseType) => {
    var canvas = document.querySelector("div#renderer-container > canvas")
    canvas.classList.remove('mouseup')
    canvas.classList.remove('mousedown')
    canvas.classList.remove('mousepoint')
    canvas.classList.add(mouseType)
  }
  
  onMouseMove = (event) => {
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
  }
}
